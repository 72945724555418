import React, { useState } from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import "../utils/css/visualization.css";
import SEO from "../components/seo";
import Helmet from "react-helmet";

export default function Index(props) {
  console.log("Images from sanity", props);
  const [viewImage, toggleImageView] = useState(false);
  const [selectedIndex, changeSelectedIndex] = useState(0);
  const [images, changeImages] = useState([]);
  const [thumbnails, changeThumbnails] = useState([]);

  const projectArray = props.data.allSanityVisualization.edges;

  // const matchTitle = "YOUR 3RD SPOT";
  // const newRenderingList = projectArray.filter(function(removeItem){
  //   return removeItem.node.title != matchTitle;
  // })
  // const matchTitle = ["YOUR 3RD SPOT","TESTING"];
  // const newRenderingList = projectArray.filter(removeItem =>{
  //   return !matchTitle.includes(removeItem.node.title)
  // })

  return (
    <Layout>
      <SEO
        title={`Visualization|Architect|Restaurant Designer|Boston,MA`}
        description={`Whitlock Design Group is a leader in restaurant and hospitality design with an extensive portfolio of dynamic and innovative retail, restaurant and entertainment venues.Trillium Trillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium BostonTrillium ${Math.random()}`}
      />
      <Helmet>
        <link rel="canonical" href="/rendering" />
      </Helmet>
      <div className="nav-avoider" />
      <div className="heading-wrapper">
        <h1 className="visualization-heading">Rendering</h1>
      </div>
      <div className="about-visualization">
        <div className="about-visualization__wrapper">
          <div id="about-visualization__para">
            <p>
              Through Visualization now you can view your projects in a
              panaromic view and add points of interest to your images
            </p>

            <p>
              That helps the user to understand the plan better and Point of
              interests can be cutomized by admin only.
            </p>
          </div>
        </div>
      </div>
      <main className="visualization-grid">
        {projectArray.map((edge, i) => {
          return (
            <React.Fragment key={i}>
              <Link
                to={`/rendering/${edge.node.slug && edge.node.slug.current}`}
              >
                <div>
                  <img
                    src={edge.node.mainImage && edge.node.mainImage.asset.url}
                    alt="Sample photo"
                    id="check"
                    title="sample photo"
                  />
                  <div className="centered-text">
                    <p style={{ fontSize: "clamp(0.7rem, 2.5vw, 2rem)" }}>
                      {edge.node.title}
                    </p>
                    <p>{edge.node.location}</p>
                    <p className="view-details-portfolio">VIEW PROJECT</p>
                  </div>
                </div>
              </Link>
            </React.Fragment>
          );
        })}
      </main>
    </Layout>
  );
}

// {props.data.allSanityPortfolio.edges.map((edge, i) => {
//   return (
//     <Link
//       to={`/visualization/${edge.node.slug && edge.node.slug.current}`}
//       onClick={() =>
//         localStorage.setItem(
//           "visualizationDescription",
//           edge.node.description
//         )
//       }
//     >
//       <div
//         onClick={() => {
//           changeSelectedIndex(i)
//           const imgs = []
//           const thumbs = []
//           props.data.allSanityVisualization.edges[
//             i
//           ].node.visualizationImages.forEach((imgData, idx) => {
//             imgs.push(imgData.image.asset.url)

//             thumbs.push(
//               <img
//                 key={idx}
//                 className="img-example-small"
//                 src={imgData.image.asset.url}
//               />
//             )
//           })
//           changeImages(imgs)
//           changeThumbnails(thumbs)
//           toggleImageView(true)
//         }}
//       >
//         <img
//           src={edge.node.mainImage.asset.url}
//           alt="Sample photo"
//           id="check"
//           title="sample photo"
//         />
//         <div className="centered-text">
//           <p className="visualization-list-title">{edge.node.title}</p>
//           <p>{edge.node.location}</p>
//           <p className="view-details-portfolio">VIEW PROJECT</p>

{
  /* <p>{edge.node.description}</p> */
}
//         </div>
//       </div>
//     </Link>
//   )
// })}

export const query = graphql`
  query allVisualization {
    allSanityVisualization(sort: { order: ASC, fields: priority }) {
      edges {
        node {
          title
          location
          description
          slug {
            _type
            current
          }
          mainImage {
            asset {
              url
              fixed {
                base64
                src
              }
              fluid {
                base64
                src
              }
            }
          }
          visualizationImages {
            id
            title
            image {
              _type
              asset {
                id
                url
              }
            }
          }
          visualizationVideos {
            title
            videoUrl
          }
        }
      }
    }
  }
`;
